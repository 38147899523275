<template>
    <div>
        <div class="vx-row">
            <div class="vx-col vs-xs-12 vs-sm-12 vs-lg-4 mb-base">
                <vx-card class="p-3" title="Rechtliches" title-color="black" subtitle="">
                    <p class="mb-3">
                        Das Ergebnis des Tools stellt eine unverbindliche Orientierungshilfe dar. Es handelt sich nicht um
                        eine Rechtsberatung und kann diese auch nicht ersetzen.<br>
                        <br>
                        Die Software ELSI-SAT und die Webseite (www.elsi-sat.de) befinden sich auf dem Stand 15.01.2021 und
                        werden nicht automatisch aktualisiert.
                    </p>
                </vx-card>
            </div>
            <div class="vx-col vs-xs-12 vs-sm-12 vs-lg-4 mb-base">
                <vx-card class="p-3" title="Technisches" title-color="black" subtitle="">
                    <p class="mb-3">Die Ergebnisse und Scores basieren auf den im Fragenkatalog ausgewählten
                        Multiple-Choice-Antworten. Text- und Kommentarfelder werden in der aktuellen Version nicht
                        ausgewertet.</p>
                </vx-card>
            </div>
            <div class="vx-col vs-xs-12 vs-sm-12 vs-lg-4 mb-base">
                <vx-card class="p-3" title="Genderneutralität" title-color="black" subtitle="">
                    <p class="mb-3">Der Fragenkatalog ist weitgehend genderneutral gehalten. In Einzelfällen sind
                        Personenbezeichnungen mithilfe des Gendersterns „*“ gekennzeichnet. Diese Form der Bezeichnung
                        inkludiert alle Geschlechter (männliche, weibliche, diverse Personen) gleichermaßen.</p>
                </vx-card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Hinweise"
}
</script>

<style scoped></style>
